/* eslint-disable @typescript-eslint/no-base-to-string */
/** Get the search params. */
import { isEmpty } from './utilities';
import { type IRecommendationContextValue } from '../types/recommendationTypes';
import RecommendationWidgetInitialState from '../reducers/Recommendation/RecommendationWidgetReducer/RecommendationWidgetInitialState';

interface PageData {
  node_type: string
  node_nid: string
}

interface DataLayerItem {
  page: PageData
}

export const getRecommendationWidgetParams = (): IRecommendationContextValue => {
  const searchParams = RecommendationWidgetInitialState;

  if (
    typeof window !== 'undefined' &&
    typeof window.drupalSettings !== 'undefined' &&
    !isEmpty(window.drupalSettings.recommendation_widget)
  ) {
    const { account_id: accountId, domain_key: domainKey, view_id: viewId } = window.drupalSettings.recommendation_widget;
    searchParams.account_id = accountId;
    searchParams.domain_key = domainKey;
    searchParams.view_id = viewId;
  }
  if (
    typeof window.dataLayer !== 'undefined' &&
    (window.dataLayer as DataLayerItem[]).length > 0 &&
    (window.dataLayer as DataLayerItem[])[0]?.page?.node_type === 'destination'
  ) {
    searchParams.cat_id = (window.dataLayer as DataLayerItem[])[0]?.page?.node_nid;
  }

  return searchParams;
};

/**
 * A function to format the recommendation object into a query string.
 *
 * @param {*} recommendation
 */
export const getRecommendationQueryString = (recommendation: IRecommendationContextValue): string => Object.keys(recommendation)
  .filter((key) => (typeof recommendation[key as keyof IRecommendationContextValue] !== 'undefined' && key !== 'widget' && recommendation[key as keyof IRecommendationContextValue].toString().length > 0)).map((key) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const searchQuery = `${key}=${recommendation[key as keyof IRecommendationContextValue]}`;
    return searchQuery;
  })
  .join('&');

/** Get the search text from url query param. */
export const getSearchTermFromUrl = (searchPath: string, queryKey: string): string => {
  let term = '';
  const searchParams = new URLSearchParams(searchPath);

  if (typeof searchParams !== 'undefined') {
    term = searchParams.get(queryKey) ?? '';
  }

  return term;
};
